<!-- 三牛平台分类背景色版本-分类标题距左边动态间距 -->
<template>
  <div class="index">
    <!-- 轮播图 -->
    <div class="container">
      <div class="swiper-box">
        <banner></banner>
      </div>
    </div>
    <!-- 假图 -->
    <div class="serverStyle">
      <img :src="serverImg" alt />
    </div>
    <!-- 热销产品 -->
    <div class="hotProductStyle">
      <div class="hotStyleTile">
        <span class="hotTitleLeft">热销产品</span>
        <span class="hotTitleRight" @click="handleToHotProduct()">
          查看全部
          <i class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div class="list-box">
        <div v-for="item in hotProductList" :key="item.id" class="listItem">
          <div class="boxImg" @click="handleToDetail(item.id)">
            <img alt :src="item.img != '' ? item.img : defaultImg" />
          </div>
          <span class="content">
            <span class="title" @click="handleToDetail(item.id)">{{
              item.goods_name
            }}</span>
            <p class="subStyle">{{ item.sub_title }}</p>
            <p class="content_footer">
              <span class="price">￥{{ item.goods_price }}</span>
              <span class="joinCart" @click="handleJoinCart(item)">
                <li class="el-icon-shopping-cart-2"></li>
                加入购物车
              </span>
            </p>
          </span>
        </div>
      </div>
    </div>
    <!-- 分类商品列表 -->
    <div class="product-box">
      <div v-for="itemA in exhibitionList" :key="itemA.category_id">
        <div class="container" :style="{ backgroundColor: itemA.color }">
          <div class="containerCont">
            <span class="containerTitle">{{ itemA.category_name }}</span>
          </div>
          <div class="wrapper">
            <!-- <div class="banner-left">
            <a>
              <img :src="itemA.img" alt />
            </a>
            </div>-->
            <home-list :list="itemA.goods" class="sortProduct"></home-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  bannerList,
  exhibitionHome,
  goodsHotPage,
  getAddCart,
} from "@/api/home";
import { cartNum } from "@/api/user";
import Banner from "@/components/Banner";
import HomeList from "@/components/HomeGoodBgList";
import serverImg from "@/assets/server.jpg";
import defaultImg from "@/assets/default.jpg";
import { getToken } from "@/utils/auth";
export default {
  name: "index",
  components: { Banner, HomeList },
  data() {
    return {
      defaultImg,
      serverImg,
      bannerData: [],
      exhibitionList: [],
      hotProductList: [],
      queryParam: {
        current: 1, // 页码
        size: 5, // 每页数量
      },
    };
  },
  created() {
    this.getBannerList();
    this.getexhibition();
    this.getHotProductList();
  },
  mounted() {},
  methods: {
    // 加入购物车
    handleJoinCart(goodsItem) {
      if (getToken()) {
        const addDatas = {
          goods_id: goodsItem.id,
          goods_name: goodsItem.goods_name,
          goods_img: goodsItem.img,
          goods_price: goodsItem.goods_price,
          goods_sum: 1,
        };
        getAddCart(addDatas).then((res) => {
          if (res.statusCode == 200) {
            this.$message.success("添加成功");
            cartNum({}).then((res) => {
              // 保存到vuex里面
              this.$store.dispatch("saveCartCount", res.data);
            });
          } else {
            this.$message.error("添加失败，请稍后重试");
          }
        });
      } else {
        this.$confirm("此操作需要登录账号, 是否前往登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    getHotProductList() {
      goodsHotPage(this.queryParam).then((res) => {
        if (res.statusCode == 200) {
          this.hotProductList = res.data;
        }
      });
    },
    getexhibition() {
      exhibitionHome().then((res) => {
        // #7b4ea3  #f85f1c  #3397e5  #f40
        // const bgColor = ["#7b4ea3", "#f85f1c", "#3397e5", "#f40"];
        // res.data.map((item, index) => {
        //   bgColor.map((itemA, indexA) => {
        //     if (index == indexA) {
        //       item.bgc = itemA;
        //     }
        //   });
        // });
        this.exhibitionList = res.data;
      });
    },

    getBannerList() {
      bannerList({}).then((res) => {
        if (res.statusCode == 200) {
          this.bannerData = res.data;
        }
      });
    },
    handleToDetail(id) {
      let routeData = this.$router.resolve({
        path: "/productDetail",
        query: { id: id },
      });
      window.open(routeData.href, "_blank");
    },
    handleToHotProduct() {
      this.$router.push({ path: "/hotProduct" });
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  background-color: $colorBGC;
  .swiper-box {
    height: 450px;
    margin-left: $leftBanner;
    .swiper-container {
      height: 451px;
      .swiper-button-prev {
        left: 214px;
      }
    }
  }
  .ads-box {
    @include flex();
    margin-top: 14px;
    margin-bottom: 31px;
    a {
      width: 296px;
      height: 167px;
    }
  }
  .banner {
    margin-bottom: 50px;
  }
  .serverStyle {
    width: 1200px;
    margin: 10px auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .hotProductStyle {
    width: 1200px;
    margin: 20px auto;
    .hotStyleTile {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 20px 0 10px;
      .hotTitleLeft {
        font-size: 22px;
      }
      .hotTitleRight:hover {
        cursor: pointer;
      }
    }
    .list-box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .listItem {
        width: 228px;
        border: 1px solid rgba(136, 136, 136, 0.3);
        // margin: 20px 37px 0 0;
        margin-right: 15px;
        text-decoration: none;
        background-color: #ffffff;
        border-top: 2px solid $colorA;
        box-sizing: border-box;
        padding: 10px;
      }
      .listItem:hover {
        // border: 1px solid $colorA;
        // color: $colorA;
        box-shadow: 0 5px 5px #888;
      }
      .listItem:nth-child(5n) {
        margin-right: 0;
      }
      .boxImg {
        width: 100%;
        height: 150px;
        overflow: hidden;
        img {
          width: 100%;
          height: 150px;
        }
      }
      // img:hover {
      //   -webkit-transform: scale(1.2);
      //   -moz-transform: scale(1.2);
      //   -ms-transform: scale(1.2);
      //   -o-transform: scale(1.2);
      //   transform: scale(1.2);
      //   transition: all 300ms linear;
      // }
      .price {
        text-align: left;
        float: left;
        position: relative;
        top: 10px;
      }
      .content {
        padding: 5px 10px;
        .title {
          padding: 0 15px;
          text-align: left;
          color: black;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 180px;
          height: 30px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .title:hover {
          cursor: pointer;
          color: $colorA;
        }
        .subStyle {
          padding: 0 15px;
          text-align: left;
          width: 180px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          word-break: break-all;
        }
        .content_footer {
          height: 30px;
          padding: 10px 10px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .price {
            height: 30px;
            font-weight: bold;
            color: $colorPrice;
          }
          .joinCart {
            width: 80px;
            height: 25px;
            padding: 0 5px;
            border-radius: 4px;
            line-height: 25px;
            text-align: center;
            color: #888;
            border: 1px solid #e5e5e5;
          }
          .joinCart:hover {
            cursor: pointer;
            background-color: $colorA;
            color: #ffffff;
          }
        }
      }
    }
  }
  .product-box {
    width: 1200px;
    margin: 0 auto;
    // background: $colorJ;
    padding: 10px 0 50px;
    .container {
      //   background-color: #d2364c;
      border-radius: 18px;
      padding: 10px;
      margin: 20px 0;
      .containerCont {
        display: inline-block;
        font-size: 22px;
        width: 100%;
        height: 40px;
        text-align: $SORTPOSITION;
        margin: 0 0 10px 30px;
        line-height: 40px;
        color: $colorB;
        padding-top: 20px;
        // border-bottom: 2px solid $colorSORTBG;
        .containerTitle {
          padding: 2px $SORTLEFTPADDING;
          color: #ffffff;
          // background-color: $colorSORTBG;
          // color: $colorSORT;
          // clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%);
        }
      }
      .wrapper {
        //   display: flex;
        margin-bottom: 20px;
        height: 600px;
        //   .banner-left {
        //     width: 210px;
        //     height: 100%;
        //     display: inline-block;
        //     vertical-align: middle;
        //     -webkit-transform: perspective(1px) translateZ(0);
        //     transform: perspective(1px) translateZ(0);
        //     box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        //     position: relative;
        //     img {
        //       width: 100%;
        //       height: 100%;
        //     }
        //   }
        .banner-left:before {
          pointer-events: none;
          position: absolute;
          content: "";
          height: 0;
          width: 0;
          bottom: 0;
          left: 0;
          background: #f5f5f5;
          /* IE9 */
          background: linear-gradient(
            45deg,
            #f5f5f5 45%,
            #aaa 50%,
            #ccc 56%,
            #f5f5f5 80%
          );
          box-shadow: 1px -1px -1px rgba(0, 0, 0, 0.4);
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-property: width, height;
          transition-property: width, height;
        }
        .banner-left:hover {
          cursor: pointer;
        }
        .banner-left:hover:before,
        .banner-left:focus:before,
        .banner-left:active:before {
          width: 68px;
          height: 68px;
        }
        .sortProduct {
          // margin-left:40px;
        }
      }
    }
  }
}
</style>
